
import { Component, Prop } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import { DateFormatMixin, HeMixin } from '@/mixins'
import { Customer } from "@/store/modules";

@Component
export class CustomerMainInfo extends mixins(DateFormatMixin, HeMixin) {
  @Prop(Customer)
  private customer!: Customer;
}

export default CustomerMainInfo;
