
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { Customer, CustomerFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from "@/@types";

@Component({
  components: {},
})
export class CustomerList extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: Customer[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: CustomerFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  fields: CDataTableFields = [
    { key: "first_name", label: "Name", filter: true, sorter: false },
    { key: "business_name", label: "Business Name", filter: true, sorter: true },
    { key: "phone", label: "Phone", filter: true, sorter: false },
    { key: "action", label: "", filter: false, sorter: false },
  ];

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: CustomerFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }

  onRowClick(item: Customer) {
    this.$router.push(`/customer/${item.ID}`);
  }

  onDelete(item: Customer) {
    // console.log('hi', item)
    this.$emit("delete", item);
  }
}

export default CustomerList;
