
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin } from "@/mixins";
import { CDataTableFields } from '@/@types';
import { TaskScheduleFilterQueryPayload, CustomerAddressInterface, Customer } from '@/store/modules';
import CustomerAddressRow from "./CustomerAddressRow.vue";

@Component({
  components: { CustomerAddressRow }
})
export class CustomerAddressesList extends mixins(DateFormatMixin, HeMixin) {
  // @Prop(Customer)
  // private customer!: Customer;

  @Prop({ default: () => [] })
  private items!: CustomerAddressInterface[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: TaskScheduleFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  fields: CDataTableFields = [
    { key: "name", label: "Type" },
    { key: "ID", label: "Address", filter: false },
    { key: "date", label: "Added on", filter: false },
  ];

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: TaskScheduleFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }
}
export default CustomerAddressesList;
