
import { Component, Vue, Prop } from "vue-property-decorator";
import { TableCardLoader } from "@/components/TableCardLoader.vue";
import { Customer, TaskSchedule } from "../../../store/modules";
import TaskScheduleList from "@/pages/task-schedule/TaskScheduleList.vue";


@Component({
  components: { TableCardLoader, TaskScheduleList },
})
export class CustomerTaskList extends Vue {
  @Prop(Customer)
  customer!: Customer;

  loading = true;

  mounted() {
    this.$nextTick(() => this.fetchRender());
  }

  get items(): Array<any> {
    const data = TaskSchedule.query().with('Tech').get();
    return data;
  }

  async fetchRender() {
    this.loading = true;

    await TaskSchedule.dispatch("customerSchedule", {
      customerID: this.customer.ID
    });

    this.loading = false;
  }
}

export default CustomerTaskList;
