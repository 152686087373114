
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  Customer,
  CustomerInterface
  // Technician,
  // TechnicianInterface,
  // GeoLocation,
  // Timesheet,
  // TaskSchedule,
  // PartRequest,
} from "@/store/modules";
import CustomerMainInfo from "./detail/CustomerMainInfo.vue";
import CustomerAddressesList from "./detail/CustomerAddressesList.vue";
import CustomerMRList from "./detail/CustomerMRList.vue";
import CustomerTaskList from "./detail/CustomerTaskList.vue";


@Component({
  components: {
    CustomerMainInfo,
    CustomerAddressesList,
    CustomerMRList,
    CustomerTaskList
  },
})
export default class CustomerDetail extends Vue {
  @Prop([String, Number])
  readonly id!: string | number;

  customer: CustomerInterface | null = null;

  loading = true;

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  async updateData() {
    let customer = this.findRecord();

    if (!customer) {
      this.loading = true;

      await Customer.dispatch("findOne", { id: +this.id });

      customer = this.findRecord();
    }

    // await TaskSchedule.dispatch("techSchedule", { techId: +this.id });
    // await GeoLocation.dispatch("byTech", {
    //   techId: +this.id,
    //   sort: "time",
    //   limit: 1,
    // });
    // // await Timesheet.dispatch("findByTech", { techId: +this.id });
    // await PartRequest.dispatch("findByTech", { techId: +this.id });

    this.customer = this.findRecord();
    console.info("customer: ", this.customer);

    this.loading = false;
  }

  // get loading(): boolean {
  //   // return true;
  //   return !!Technician.store().state.entities.mr.fetching;
  // }

  findRecord(): Customer | null {
    return Customer.query()
      // .with('geoLocations')
      // .with('taskSchedules')
      // .with('partRequests')
      .with('addresses')
      // .withAllRecursive()
      .whereId(+this.id)
      .first();
  }

  /*get jobId(): number | null {
    const technician = this.technician;
    return technician && technician.jobID ? technician.jobID : null;
  }*/
}
