
import { Component, Vue, Prop } from "vue-property-decorator";
import { AddressInterface, Address } from "@/store/modules";


@Component({
  components: {},
})
export default class CustomerAddressRow extends Vue {
  @Prop([String, Number])
  readonly id!: string | number;

  address: AddressInterface | null = null;

  loading = true;

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  async updateData() {
    let address = this.findRecord();

    if (!address) {
      this.loading = true;

      await Address.dispatch("findOne", { id: +this.id });

      address = this.findRecord();
    }

    this.address = this.findRecord();
    // console.log(`this.address`, this.address)
    this.loading = false;
  }

  findRecord(): Address | null {
    return Address.query()
      // .with('Region')
      // .with('Country')
      .whereId(+this.id)
      .first();
  }
}
