
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { TableCardLoader } from "@/components/TableCardLoader.vue";
import { Customer, MR, MRAllPayload, MRFilterQueryPayload } from "../../../store/modules";
import MRList from "@/pages/mr/MRList.vue";
import { filterEmptyQuery, queryFilterHasChange } from "../../../utility/QueryFilter";

const defaultFilters: MRFilterQueryPayload = {
  // completed: 0,
  // voided: 0,
};

@Component({
  components: { TableCardLoader, MRList },
})
export class TaskMRList extends Vue {
  @Prop(Customer)
  customer!: Customer;

  loading = true;

  colFilter: MRFilterQueryPayload = { ...defaultFilters };

  mounted() {
    this.$nextTick(() => this.fetchRender());
  }

  get items(): Array<any> {
    const { ID } = this.customer;
    const data = MR.query()
      .orderBy("created_at", 'desc')
      // .where("customerID", (val: number | null) => val === ID)
      .get();
    console.log("get", data);
    return data;
  }

  async fetchRender() {
    this.loading = true;

    await MR.dispatch("fetchData", {
      filter: {
        customerID: this.customer.ID,
        ...(this.compileFilter())
      },
      options: {
        replace: false,
      },
      sort: 'created_at',
      order: 'desc'
    });

    this.loading = false;
  }

  @Watch("colFilter")
  onColFilterChange(
    val: MRFilterQueryPayload | null,
    oldVal: MRFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    if (queryFilterHasChange<MRFilterQueryPayload>(val, oldVal)) {
      this.$nextTick(() => this.fetchRender());
    }
  }

  compileFilter(): MRAllPayload["filter"] {
    let f = filterEmptyQuery(this.colFilter);

    // if (this.filterTechs && this.filterTechs.length) {
    //   f.techID = (this.filterTechs || []).map((tech) => tech.ID).join(",");
    // }

    const timeRange = [];

    if (this.colFilter.startDate || this.colFilter.endDate) {
      timeRange.push(this.colFilter.startDate || "");
      timeRange.push(
        this.colFilter.endDate || new Date(Date.now()).toISOString()
      );
      delete f.startDate;
      delete f.endDate;
    }

    if (timeRange.length) {
      f.created_at = timeRange.join(",");
    }


    return { ...f };
  }
}

export default TaskMRList;
