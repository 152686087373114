
import { Component, Watch, Vue } from "vue-property-decorator";
import CustomerList from "./CustomerList.vue";
import {
  CustomerFilterQueryPayload,
  CustomerAllPayload,
  Customer,
} from "@/store/modules";
import { CDataTableSortVal } from "@/@types";
import { queryFilterHasChange, filterEmptyQuery } from "@/utility";
import { SortQueryPayload } from "@/store/modules";
// import CustomerCategoryMultiDropdown from "../customer-category/filters/CustomerCategoryMultiDropdown.vue";

@Component({
  components: { CustomerList },
})
export default class CustomerListHandler extends Vue {
  limit = 25;

  colFilter: CustomerFilterQueryPayload = {};

  currentPage = 1;

  sort: SortQueryPayload = {
    sort: "created_at",
    order: "desc",
  };

  sortVal: CDataTableSortVal = {};

  // filterCategories!: CustomerCategory[];

  // @Prop(Number)
  // category?: number;

  @Watch("colFilter")
  onColFilterChange(
    val: CustomerFilterQueryPayload | null,
    oldVal: CustomerFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    if (queryFilterHasChange<CustomerFilterQueryPayload>(val, oldVal)) {
      this.load();
    }
  }

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || this.sort.sort || "",
      order: val.asc ? "asc" : "desc",
    };
    this.load();
  }

  filterChange(e: any) {
    switch ("" + e.target.value) {
      case "1":
        this.$nextTick(() => this.fetchRender(this.compilePayload({})));
        return;
    }

    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: CustomerAllPayload) {
    await Customer.dispatch("all", payload);
  }

  get items(): Array<any> {
    const data = Customer.query().withAll().get();
    return data;
  }

  get loading(): boolean {
    return !!Customer.store().state.entities.customer.fetching;
  }

  get total() {
    return Customer.store().state.entities.customer.total;
  }

  get numPages(): number {
    const total = Customer.store().state.entities.customer.total;
    // console.log('numPages', total)
    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  onDelete(item: Customer) {
    const { first_name, last_name } = item;
    const h = this.$createElement;
    const content = h("p", [
      "Are you sure you want to delete this Customer",
      h("strong", { class: "mx-1" }, `${first_name} ${last_name}`),
      ". ",
      "This action is not reversible.",
    ]);
    this.$bvModal
      .msgBoxConfirm([content], {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true,
      })
      .then((state: boolean) => {
        if (!state) {
          return;
        }

        alert("not supported yet");
      });
  }

  compilePayload(merge: Partial<CustomerAllPayload> = {}): CustomerAllPayload {
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: filterEmptyQuery(this.colFilter) },
      ...merge,
    };
  }

  // compileFilter(): CustomerAllPayload["filter"] {
  //   const f = filterEmptyQuery(this.colFilter);
  //   // if (this.filterCategories && this.filterCategories.length) {
  //   //   f.category_id = (this.filterCategories || [])
  //   //     .map((cat) => cat.id)
  //   //     .join(",");
  //   // }
  //   return { ...f };
  // }

  // onCatSelect(options: CustomerCategory[]) {
  //   this.filterCategories = options;
  //   this.load();
  // }
}
